import type { FC } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  active: boolean
  to: string
}

const NavigationLink: FC<Props> = ({
  active = false,
  children,
  to,
  ...props
}) => (
  <div className="mr-8 h-21 relative flex items-center">
    <Link
      to={to}
      {...props}
      className={`block mt-4 lg:inline-block lg:mt-0 hover:opacity-75 font-sans text-lg ${
        active && 'font-helvetica-b'
      }`}
    >
      {children}
    </Link>
    {active && <div className="w-11/12 h-0.5 bg-blue absolute bottom-0" />}
  </div>
)
export default NavigationLink
