export const MAIN = '/'
export const SING_IN = '/'
export const MY_PAGE = '/my-page'
export const TEAMMATES = '/teammates'
export const REPORTS = '/reports'
export const ADD_RESUME = '/add-resume'
export const ADD_EMPLOYEE = '/add-employee'
export const PRIVATE = '/private'
export const VACATION = '/vacation'
export const DOCUMENTS = '/documents'
export const BENEFITS = '/benefits'
export const TRAININGS = '/trainings'
export const ACTIVES = '/actives'
export const NOTES = '/notes'
export const REMARKS = '/remarks'
export const ALTERNATIVE_CONTACT = '/alternative-contact'
export const NEWS = '/news'
export const SINGLE_NEWS = '/news/:id'
export const ADD_NEWS = '/add-news'
export const SETTINGS = '/settings'
export const ROLES = '/roles'
export const COWORKERS = '/coworkers'
export const HIRING = '/hiring'
export const KPI = '/kpi'
export const HOLIDAYS = '/holidays'
export const EXPERIENCE = '/experience'
export const ADD_COMPANY = '/add-company'
export const ADD_GRADE = '/add-grade'
export const ADD_POSITION = '/add-position'
export const ADD_POSITION_UNIT = '/add-pos-structural-unit'
export const ADD_STRUCTURAL_UNIT = '/add-structural-unit'
export const ADD_ASSET = '/add-asset'
export const ADD_BENEFIT = '/add-benefit'
export const ADD_SCHEDULE = '/add-schedule'
export const APPROVAL = '/approval'
export const EMPLOYEES = '/employees'
export const ADD_GROUP = '/add-group'
export const EDUCATION = '/education'
export const REQUESTS = '/requests'
