import { StrictMode, Suspense, lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ReactDOM from 'react-dom'
import App from '@/App'
import Loader from '@/components/common/loader/Loader'

// utils
import reportWebVitals from '@/reportWebVitals'
import ProtectedRoute from '@/middleware/ProtectedRoute'

// routes
import { routes as employeeRoutes } from '@/components/pages/employee/configs/routes'
import { routes as personalPageRoutes } from '@/components/pages/personalPage/configs/routes'
import { routes as authRoutes } from '@/components/pages/auth/configs/routes'
import { routes as settingsRoutes } from '@/components/pages/settings/configs/routes'
import { routes as notificationRoute } from '@/components/pages/notifications/configs/routes'
import { routes as requestRoute } from '@/components/pages/approvalRequests/configs/routes'

import { QueryClient, QueryClientProvider } from 'react-query'

// styles
import '@/app.scss'
import '@/tailwind.css'
import 'react-quill/dist/quill.snow.css'
import 'react-toastify/dist/ReactToastify.css'

// modules
const MainPage = lazy(() => import('@/components/pages/mainPage/MainPage'))
const PersonalPageModule = lazy(() => import('@/components/pages/personalPage'))
const AuthModule = lazy(() => import('@/components/pages/auth'))
const EmployeeModule = lazy(() => import('@/components/pages/employee'))
const NotificationModule = lazy(
  () => import('@/components/pages/notifications')
)
const SettingsModule = lazy(() => import('@/components/pages/settings'))
const RequestModule = lazy(() => import('@/components/pages/approvalRequests'))

const ContactsModule = lazy(() => import('@/components/pages/contacts'))
const BirthdaysModule = lazy(() => import('@/components/pages/birthdays'))

const ChangesAttendance = lazy(
  () => import('@/components/pages/changesAttendance')
)

// router element
const AuthModuleElement = (
  <Suspense fallback={<Loader />}>
    <AuthModule />
  </Suspense>
)

const MainPageElement = (
  <Suspense fallback={<Loader />}>
    <ProtectedRoute>
      <MainPage />
    </ProtectedRoute>
  </Suspense>
)

const PersonalPageModuleElement = (
  <Suspense fallback={<Loader />}>
    <ProtectedRoute>
      <PersonalPageModule />
    </ProtectedRoute>
  </Suspense>
)

const EmployeeModuleElement = (
  <Suspense fallback={<Loader />}>
    <ProtectedRoute>
      <EmployeeModule />
    </ProtectedRoute>
  </Suspense>
)

const NotificationModuleElement = (
  <Suspense fallback={<Loader />}>
    <ProtectedRoute>
      <NotificationModule />
    </ProtectedRoute>
  </Suspense>
)

const SettingsModuleElement = (
  <Suspense fallback={<Loader />}>
    <ProtectedRoute>
      <SettingsModule />
    </ProtectedRoute>
  </Suspense>
)

const RequestModuleElement = (
  <Suspense fallback={<Loader />}>
    <ProtectedRoute>
      <RequestModule />
    </ProtectedRoute>
  </Suspense>
)
const ContactsModuleElement = (
  <Suspense fallback={<Loader />}>
    <ProtectedRoute>
      <ContactsModule />
    </ProtectedRoute>
  </Suspense>
)
const BirthdaysModuleElement = (
  <Suspense fallback={<Loader />}>
    <ProtectedRoute>
      <BirthdaysModule />
    </ProtectedRoute>
  </Suspense>
)
const ChangesAttendanceElement = (
  <Suspense fallback={<Loader />}>
    <ProtectedRoute>
      <ChangesAttendance />
    </ProtectedRoute>
  </Suspense>
)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 60000
    }
  }
})

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={MainPageElement} />
            <Route path={`${authRoutes.basePath}/*`} element={AuthModuleElement} />
            <Route
              path={`${personalPageRoutes.basePath}/*`}
              element={PersonalPageModuleElement}
            />
            <Route
              path={`${employeeRoutes.basePath}/*`}
              element={EmployeeModuleElement}
            />
            <Route
              path={`${notificationRoute.basePath}/*`}
              element={NotificationModuleElement}
            />
            <Route
              path={`${settingsRoutes.basePath}/*`}
              element={SettingsModuleElement}
            />
            <Route
              path={`${requestRoute.basePath}/*`}
              element={RequestModuleElement}
            />
            <Route path="/contacts" element={ContactsModuleElement} />
            <Route path="/birthdays" element={BirthdaysModuleElement} />
            <Route
              path={`${personalPageRoutes.children.changesAttendance.basePath}/*`}
              element={ChangesAttendanceElement}
            />
          </Route>
        </Routes>
      </QueryClientProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
