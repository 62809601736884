// utils
import classNames from 'classnames'

// components
import Error from '@/components/common/error/Error'

// assets
import { ErrorIcon } from '@/assets/icons'

// types
import { ChangeEvent, forwardRef, HTMLProps, KeyboardEvent } from 'react'
import { overrideTailwindClasses } from 'tailwind-override'

export type InputProps = {
  label?: string
  icon?: any
  error?: any
  required?: boolean
  type?: string
  placeholder?: string
  className?: string
  wrapperClassName?: string
  labelClassName?: string
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
} & HTMLProps<HTMLInputElement>

const Input = forwardRef<HTMLInputElement | undefined, InputProps>(
  ({
    label = '',
    type = 'text',
    icon,
    error,
    required,
    onKeyDown,
    onInput,
    maxLength,
    placeholder = 'ჩაწერეთ',
    className,
    wrapperClassName,
    labelClassName,
    ...props
  }, ref) => {
    const Icon = icon

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      const { key } = e
      const allowedKeys = [
        'Backspace',
        'Tab',
        'Enter',
        'Escape',
        'ArrowLeft',
        'ArrowRight',
        'ArrowUp',
        'ArrowDown'
      ]
      const numberAllowedKeys = [...allowedKeys, '.']

      if (Number.isNaN(+key)) {
        if (type === 'wholeNumber' && !allowedKeys.includes(key)) {
          e.preventDefault()
        }
        if (type === 'number' && !numberAllowedKeys.includes(key)) {
          e.preventDefault()
        }
      }
      if (typeof onKeyDown === 'function') {
        onKeyDown(e)
      }
    }

    const handleOnInput = (e: ChangeEvent<HTMLInputElement>) => {
      if (type === 'number' && maxLength) {
        const { value } = e.target
        const newValue = value.replace(/[^0-9]/g, '')

        if (newValue.length > maxLength) {
          e.target.value = newValue.slice(0, maxLength)
        }
      }
      if (typeof onInput === 'function') {
        onInput(e)
      }
    }

    return (
      <div
        className={overrideTailwindClasses(
          classNames('w-full relative', wrapperClassName)
        )}
      >
        {label && (
          <label
            className={overrideTailwindClasses(
              classNames(
                'block text-black text-sm font-bold mb-2',
                labelClassName
              )
            )}
            htmlFor="username"
          >
            {label}
            {required && <span className="text-red ml-2">*</span>}
          </label>
        )}
        <div className="w-full relative">
          <input
            className={overrideTailwindClasses(
              classNames(
                'border',
                'border-solid',
                'w-full',
                'h-10',
                'py-2',
                'px-3',
                'text-inputText',
                'rounded-lg',
                'leading-tight',
                'focus:outline-none',
                'focus:shadow',
                'focus:shadow-outline',
                'hover:border-borderGrayInputHover',
                'focus:border-blueInputFocus',
                {
                  'border-red': !!error === true,
                  'border-borderGray': !!error === false,
                  'pr-9 pl-3': !!icon
                },
                className
              )
            )}
            maxLength={maxLength}
            type={type}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            onInput={handleOnInput}
            {...props}
          />
          {icon && !error && (
            <div className="absolute right-3 top-2">
              <Icon />
            </div>
          )}
          {error && (
            <div className="absolute right-3 top-2">
              <ErrorIcon />
            </div>
          )}
        </div>
        {error && <Error text={error} />}
      </div>
    )
  }
)
export default Input
