import {
  DocumentIcon,
  PersonBlueIcon,
  TeamMatesIcon,
  TeamIcon,
  RequestsIcon,
  ShiftsIcon,
  BirthdayIcon,
  KPIIcon,
  ReportIcon,
  SurveyIcon,
  NotificationMainIcon
} from '@/assets/icons'
import { routes as personalPageRoutes } from '../components/pages/personalPage/configs/routes'
import { routes as approvalRequestsRoutes } from '../components/pages/approvalRequests/configs/routes'

export const cardsData = [
  {
    icon: PersonBlueIcon,
    text: 'პირადი კაბინეტი',
    to: personalPageRoutes.basePath
  },
  {
    icon: TeamMatesIcon,
    text: 'ჩემი გუნდი'
    // to: `${employeeRoutes.basePath}/${employeeRoutes.children.organizationSchema.basePath}`
  },
  {
    icon: TeamIcon,
    text: 'კონტაქტები',
    to: '/contacts'
  },
  {
    icon: DocumentIcon,
    text: 'დოკუმენტაცია',
    to: `${personalPageRoutes.basePath}/${personalPageRoutes.children.documents.basePath}`
  },
  {
    icon: RequestsIcon,
    text: 'მოთხოვნები',
    to: approvalRequestsRoutes.basePath
  },
  {
    icon: NotificationMainIcon,
    text: 'შეტყობინებები',
    to: `${personalPageRoutes.children.notifications.basePath}`
  },
  {
    icon: ShiftsIcon,
    text: 'ცვლები და დასწრება',
    to: `${personalPageRoutes.children.changesAttendance.basePath}`
  },
  {
    icon: BirthdayIcon,
    text: 'დაბადების დღეები',
    to: '/birthdays'
  },
  {
    icon: KPIIcon,
    text: 'KPI'
  },
  {
    icon: ReportIcon,
    text: 'რეპორტი'
  },
  {
    icon: SurveyIcon,
    text: 'განცხადებები და გამოკითხვა'
  }
]

export const slides = [
  {
    name: 'თორნიკე  გაგაძე',
    company: 'Tesla',
    position: 'CEO',
    description: `შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და
      ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ
      შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას
      საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.`,
    avatar: 'https://thispersondoesnotexist.com/image',
    date: new Date()
  },
  {
    name: 'სახელი გვარი',
    company: 'microsoft',
    position: 'CTO',
    description: `
      ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ
      შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას
      საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.`,
    avatar: 'https://thispersondoesnotexist.com/image',
    date: new Date()
  },
  {
    name: 'Elon Musk',
    company: 'Tesla',
    position: 'CEO',
    description: `შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და
      ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ
      შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას
      საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.`,
    avatar: 'https://thispersondoesnotexist.com/image',
    date: new Date()
  }
]

export const news = [
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://picsum.photos/id/11/600/400',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://picsum.photos/id/50/600/400',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://picsum.photos/id/40/600/400',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://picsum.photos/id/100/600/400',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://picsum.photos/id/200/600/400',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://picsum.photos/id/201/600/400',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://picsum.photos/id/199/600/400',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://picsum.photos/id/189/600/400',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://picsum.photos/200/300',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  },
  {
    author: {
      name: 'სახელი გვარი',
      position: 'პოზიცია',
      company: 'შპს ჯორჯიან მანგანეზი',
      avatar: 'https://thispersondoesnotexist.com/image'
    },
    coverImage: 'https://source.unsplash.com/random',
    title: 'სათაური',
    text: 'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ნაწარმის შემქმნელებს, რეალურთან მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ხშირადაა შემთხვევა, როდესაც დიზაინის შესრულებისას საჩვენებელია, თუ როგორი იქნება ტექსტის ბლოკი.',
    date: new Date()
  }
]
