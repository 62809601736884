import { useState } from 'react'
import { useQuery } from 'react-query'
import { request } from '@/utils/axiosBase/axiosBase'
import dayjs from 'dayjs'

type Props = {
  values?: any
  resumeId?: number | string | undefined
}

export const useResumesRequest = ({ values, resumeId }: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const url: string = 'employees/resumes'

  const { data: resumes } = useQuery(['resumes', values, currentPage], () =>
    request({
      url: `${url}?paginate=true&page=${currentPage}&full_name=${
        values?.fullname || ''
      }
      &tabel_num=${values?.tabel_num || ''}&sid=${values?.sid || ''}
      &position_element_id=${
        values?.position_element_id || ''
      }&employee_status=${values?.employee_status || []}
      &start_date=${
        values?.start_date ? dayjs(values.start_date).format('YYYY-MM-DD') : ''
      }`,
      method: 'get'
    })
  )

  const { data: resume } = useQuery([`resume-${resumeId}`, resumeId], () =>
    request({
      url: `${url}/${resumeId || ''}`,
      method: 'get'
    })
  )

  return {
    resumes: resumes?.data?.data || [],
    totalItem: resumes?.data?.data?.total,
    totalPages: resumes?.data?.meta.last_page,
    setCurrentPage,
    currentPage,
    currentResume: resume?.data?.data || [],
    resume: resume?.data?.data || []
  }
}
