/* eslint-disable max-len */
import { useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import Input from '@/components/common/input/Input'
import Avatar from '@/components/common/avatar/Avatar'

import useOutsideClickDetector from '@/hooks/useOutsideClickDetector'
import { useHeaderRequests } from './hooks'

import { MAIN, REPORTS, SETTINGS } from '@/constants/client'

import { news } from '@/mock'

import {
  NotificationsIcon,
  PersonIcon,
  SettingsIcon,
  SearchIcon
} from '@/assets/icons'

import NavigationLink from './NavigationLInk'
import { useAuth } from '../../hooks/useAuth'
import { routes } from '../pages/notifications/configs/routes'

import Logo from '../../assets/img/logo.png'
import { useResumesRequest } from '../pages/employee/pages/employee/hooks/useResumesRequest'

const DropDown = ({ children }: any) => (
  <div className="absolute -right-6 mt-2 bg-white rounded-md shadow-lg z-20 w-[21rem] cursor-auto">
    <div className="w-3 h-3 absolute -top-1 right-30px bg-white transform -rotate-45 z-10" />
    {children}
  </div>
)

const Header = () => {
  const path = useLocation()

  const { totalNotificationsCount } = useHeaderRequests()
  const { pathname } = path
  const navigate = useNavigate()
  const [personalDropdownVisible, setPersonalDropdownVisible] = useState(false)
  const [notificationDropdownVisible, setNotificationDropdownVisible] =
    useState(false)
  const [showSearchResults, setShowSearchResults] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const notificationRef = useRef<HTMLDivElement>(null)
  const personalInfoRef = useRef<HTMLDivElement>(null)
  const searchResultsRef = useRef<HTMLDivElement>(null)

  const { user, signOut } = useAuth()

  // custom hooks
  const { resume } = useResumesRequest({ resumeId: user?.data?.resumeId || '' })

  useOutsideClickDetector(notificationRef, () => {
    setNotificationDropdownVisible(false)
  })

  useOutsideClickDetector(personalInfoRef, () => {
    setPersonalDropdownVisible(false)
  })
  useOutsideClickDetector(searchResultsRef, () => {
    setShowSearchResults(false)
  })

  return (
    <div className="flex noPrint justify-center bg-white">
      <header className="w-full h-21 flex items-center px-10 bg-white">
        <nav className="w-full flex items-center justify-between  font-helvetica">
          <div className="flex items-center flex-shrink-0  mr-6">
            <img className="w-10" src={Logo} alt="logo" />
          </div>
          {user && (
            <>
              <div className="block lg:hidden">
                <button
                  className="flex items-center px-3 py-2 border rounded"
                  type="button"
                >
                  <svg
                    className="fill-current h-3 w-3"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                  </svg>
                </button>
              </div>
              <div className="w-full  flex-grow lg:flex lg:items-center lg:w-full flex justify-between">
                <div className="text-sm flex lg:flex-grow">
                  <NavigationLink to="/" active={pathname === MAIN}>
                    მთავარი
                  </NavigationLink>
                  {user?.data?.resumeId && (
                    <NavigationLink
                      to="resumes/employee"
                      active={pathname.includes('employee') && !path.search}
                    >
                      პირადი კაბინეტი
                    </NavigationLink>
                  )}
                  <NavigationLink
                    to="resumes"
                    active={
                      pathname.includes('resumes') &&
                      !pathname.includes('employee')
                    }
                  >
                    თანამშრომლები
                  </NavigationLink>
                  <NavigationLink to="#" active={pathname.includes(REPORTS)}>
                    რეპორტები
                  </NavigationLink>
                </div>
                <div className="flex">
                  <div
                    className={`w-44 mr-5 relative focus-within:w-100 transition-all ${
                      searchTerm ? 'w-100' : ''
                    }`}
                    ref={searchResultsRef}
                  >
                    <Input
                      name="search"
                      placeholder="ძებნა..."
                      icon={SearchIcon}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.currentTarget.value)}
                      onFocus={() => setShowSearchResults(true)}
                    />
                    {searchTerm && showSearchResults && (
                      <div className="absolute bg-white w-full top-12 rounded-md shadow-lg z-50 overflow-hidden border border-borderLightGray">
                        <div
                          className=" max-h-500 overflow-y-scroll p-3 "
                          id="journal-scroll"
                        >
                          <div className="bg-aliceBlue h-7 rounded-xl text-center flex items-center justify-center">
                            <span>თანამშრომლები</span>
                          </div>
                          <div className="mt-4">
                            <div className="flex mb-4">
                              <img
                                src="https://thispersondoesnotexist.com/image"
                                alt="person"
                                className="w-14 h-14 rounded-full mr-3"
                              />
                              <div className="flex flex-col text-xs">
                                <span className="text-sm font-helvetica-b">
                                  სახელი გვარი
                                </span>
                                <span>პოზიცია</span>
                                <span>სტრუქტურული ერთეული</span>
                              </div>
                            </div>

                            <div className="flex mb-4">
                              <img
                                src="https://thispersondoesnotexist.com/image"
                                alt="person"
                                className="w-14 h-14 rounded-full mr-3"
                              />
                              <div className="flex flex-col text-xs">
                                <span className="text-sm font-helvetica-b">
                                  სახელი გვარი
                                </span>
                                <span>პოზიცია</span>
                                <span>სტრუქტურული ერთეული</span>
                              </div>
                            </div>

                            <div className="flex mb-4">
                              <img
                                src="https://thispersondoesnotexist.com/image"
                                alt="person"
                                className="w-14 h-14 rounded-full mr-3"
                              />
                              <div className="flex flex-col text-xs">
                                <span className="text-sm font-helvetica-b">
                                  სახელი გვარი
                                </span>
                                <span>პოზიცია</span>
                                <span>სტრუქტურული ერთეული</span>
                              </div>
                            </div>
                          </div>
                          <div className="bg-aliceBlue h-7 rounded-xl text-center flex items-center justify-center">
                            <span>სიახლეები</span>
                          </div>
                          <div className="mt-4">
                            {news.slice(0, 10).map((n, i) => (
                              // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                              <div
                                className="flex mb-4"
                                role="button"
                                onClick={() => {
                                  navigate(`/news/${i}`)
                                  setShowSearchResults(false)
                                  setSearchTerm('')
                                }}
                              >
                                <img
                                  src={n.coverImage}
                                  alt="person"
                                  className="w-24 h-14 rounded-lg mr-3 object-cover"
                                />
                                <div className="flex flex-col text-xs">
                                  <span className="text-sm font-helvetica-b">
                                    {n.title}
                                  </span>
                                  <span className="text-gray text-xxs line-clamp-3 leading-3">
                                    {n.text}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex justify-around items-center bg-lightBlue h-10 px-2 w-32 py-3 rounded-lg">
                    <div className="pr-2 pl-2 text-white bg-[#e53e3e] flex rounded-md items-center justify-center">
                      {totalNotificationsCount}
                    </div>

                    <div className="cursor-pointer" ref={notificationRef}>
                      <Link to={`${routes.basePath}`}>
                        <NotificationsIcon />
                      </Link>
                    </div>
                    <div className="cursor-pointer">
                      <SettingsIcon
                        onClick={() => {
                          navigate(`${SETTINGS}/companies`)
                        }}
                      />
                    </div>
                    <div
                      className="cursor-pointer relative"
                      ref={personalInfoRef}
                    >
                      <PersonIcon
                        onClick={() => {
                          setPersonalDropdownVisible((curr) => !curr)
                        }}
                      />
                      {personalDropdownVisible && (
                        <DropDown>
                          <div className="py-2 w-full">
                            <div className="flex justify-between p-4 pb-0">
                              <Avatar src={resume?.image} />
                              <div className="flex flex-col">
                                <p className="text-black text-base font-bold">
                                  {resume?.name}
                                </p>
                                <p className="text-black text-base font-bold">
                                  {resume?.surname}
                                </p>
                              </div>
                              <div className="flex flex-col justify-between">
                                <Link
                                  to="/"
                                  onClick={() =>
                                    signOut(() => {
                                      navigate('/auth')
                                    })
                                  }
                                  className="flex items-center w-full h-full"
                                >
                                  <p className="px-3 py-2 mb-1 rounded-lg bg-blue-3 text-white">
                                    გასვლა
                                  </p>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </DropDown>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </nav>
      </header>
    </div>
  )
}
export default Header
