import { Service } from '../Service'

class AuthService<T> extends Service {
  constructor() {
    super('auth')
  }

  me = async (): Promise<T> => {
    try {
      const url = this.urlBuilder({ paths: ['user', 'info'] })
      const response: any = await this.get(url)
      return response
    } catch (error: any) {
      return {} as T
    }
  }

  login = async (body: any): Promise<T> => {
    try {
      const url = this.urlBuilder({ paths: ['login'] })
      const response: any = await this.post(url, body)
      return response
    } catch (error: any) {
      throw Promise.reject(error)
    }
  }

  refresh = async (): Promise<T> => {
    try {
      const url = this.urlBuilder({ paths: ['refresh'] })
      const response: any = await this.post(url)
      return response
    } catch (error: any) {
      throw Promise.reject(error)
    }
  }

  logout = async (): Promise<T> => {
    try {
      const url = this.urlBuilder({ paths: ['logout'] })
      const response: any = await this.post(url)
      return response
    } catch (error: any) {
      throw Promise.reject(error)
    }
  }
}

export { AuthService }
