// hooks
import { useLayoutEffect, useState } from 'react'
import { useAuth } from '@/hooks/useAuth'
import { Navigate, useLocation } from 'react-router-dom'

// routes
import { routes } from '@/components/pages/auth/configs/routes'

// components
import Loader from '@/components/common/loader/Loader'

type Props = {
  // eslint-disable-next-line no-undef
  children: JSX.Element
}

enum AuthStates {
  LOADING,
  YES,
  NO
}

const ProtectedRoute = ({ children }: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState<AuthStates>(
    AuthStates.LOADING
  )

  const { isValid, me } = useAuth()
  const location = useLocation()

  const isUserValid = async () => {
    const validity = await isValid()
    if (!validity) {
      me(async () => {
        const nextValidity = await isValid()
        setIsAuthenticated(nextValidity ? AuthStates.YES : AuthStates.NO)
      })
    } else {
      setIsAuthenticated(AuthStates.YES)
    }
  }

  useLayoutEffect(() => {
    isUserValid()
  })

  return (
    <>
      {isAuthenticated === AuthStates.LOADING && <Loader />}
      {isAuthenticated === AuthStates.NO && (
        <Navigate
          to={`/${routes.basePath}`}
          state={{ from: location }}
          replace
        />
      )}
      {isAuthenticated === AuthStates.YES && children}
    </>
  )
}

export default ProtectedRoute
