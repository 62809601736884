// utils
import classNames from 'classnames'
import { overrideTailwindClasses } from 'tailwind-override'

type ErrorProps = {
  text: string
  className?: string
}
const Error = ({ text, className }: ErrorProps) => (
  <div
    className={overrideTailwindClasses(
      classNames('text-xs text-red font-helvetica-r h-5', className)
    )}
  >
    {text}
  </div>
)

export default Error
