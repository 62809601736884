import type { FC } from 'react';

interface Props {
  src: string;
}

const Avatar: FC<Props> = ({ src }) => (
  <img className="w-16 h-16 rounded-full" src={src} alt="avatar" />
);
export default Avatar;
