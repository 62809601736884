/** @format */

class LocalStorageUtil {
  private localStorageSupported: boolean

  constructor() {
    this.localStorageSupported =
      typeof window.localStorage !== 'undefined' && window.localStorage != null
  }

  add(key: string, item: any) {
    if (this.localStorageSupported) {
      localStorage.setItem(key, item)
    }
  }

  get(key: string): any | null {
    if (this.localStorageSupported) {
      const item = localStorage.getItem(key)
      return item
    }
    return null
  }

  remove(key: string) {
    if (this.localStorageSupported) {
      localStorage.removeItem(key)
    }
  }

  clear() {
    if (this.localStorageSupported) {
      localStorage.clear()
    }
  }
}

export { LocalStorageUtil }
