// context
import { AuthProvider } from '@/context/auth/authProvider'

// components
import { Outlet } from 'react-router-dom'
import { Suspense } from 'react'
import { RecoilRoot } from 'recoil'
import { ToastContainer } from 'react-toastify'
import Header from '@/components/header/Header'
import Layout from '@/components/layout/Layout'
import Loader from '@/components/common/loader/Loader'

const App = () => (
  <Suspense fallback={<Loader />}>
    <ToastContainer />
    <RecoilRoot>
      <AuthProvider>
        <Header />
        <Layout>
          <Outlet />
        </Layout>
      </AuthProvider>
    </RecoilRoot>
  </Suspense>
)

export default App
