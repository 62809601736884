import type { ReactNode } from 'react';

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => (
  <div className="flex justify-center px-10 bg-lightGray">
    <div className="main-content w-full bg-lightGray flex flex-col">
      {children}
    </div>
  </div>
);

export default Layout;
