/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, ReactNode, useMemo, useState } from 'react'

// services
import { AuthService } from '@/services/AuthService'

// utils
import { isEmptyValue } from '@/utils/helpers/isEmptyValue'
import { LocalStorage } from '@/utils/LocalStorage'

// types
import type { AuthContextType } from './types'

const AuthContext = createContext<AuthContextType>(null!)

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<any>(null)

  const me = async (callback: () => void) => {
    const user = await AuthService.me()

    if (!isEmptyValue(user)) {
      setUser(user)
    } else {
      LocalStorage.remove('token')
      callback()
    }
  }

  const signIn = async (credentials: any, callback: () => void) => {
    const user = await AuthService.login(credentials)

    if (!isEmptyValue(user)) {
      setUser(user)
      LocalStorage.add('token', user?.data.accessToken)
      callback()
    }
  }

  const signOut = (callback: () => void) => {
    LocalStorage.remove('token')
    setUser(null)
    callback()
  }

  const isValid = async () => {
    const token = await LocalStorage.get('token')
    return !isEmptyValue(token) && !isEmptyValue(user)
  }

  const value = useMemo(
    () => ({ user, signIn, signOut, me, isValid }),
    [user, signIn, signOut, me, isValid]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export { AuthProvider, AuthContext }

/**
 * 1) load app
 * 2) check if user token is valid +
 *    -> app load protected route, +
 *       start checking if token is valid, +
 *       check user state and localStorage, +
 *       method: isValid() +
 *    -> if null both +
 *       method: me() +
 *    -> if user is not valid redirect to login page
 *
 *
 */
