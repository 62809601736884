const routes = {
  basePath: 'settings',
  children: {
    roles: {
      basePath: 'roles',
      add: {
        basePath: 'add'
      }
    },
    coworkers: {
      basePath: 'coworkers',
      list: {
        basePath: 'list'
      }
    },
    companies: {
      basePath: 'companies',
      add: {
        basePath: 'add'
      }
    },
    grades: {
      basePath: 'grades',
      add: {
        basePath: 'add'
      }
    },
    groups: {
      basePath: 'groups',
      add: {
        basePath: 'add'
      }
    },
    positions: {
      basePath: 'positions',
      add: {
        basePath: 'add'
      }
    },
    structuralUnits: {
      basePath: 'structural_units',
      add: {
        basePath: 'add'
      },
      hierarchy: {
        basePath: 'hierarchy'
      }
    },
    actives: {
      basePath: 'actives',
      add: {
        basePath: 'add'
      }
    },
    benefits: {
      basePath: 'benefits',
      add: {
        basePath: 'add'
      }
    },
    schedules: {
      basePath: 'schedules',
      secondPath: 'shifts',
      add: {
        basePath: 'add',
        secondPath: 'add/shift'
      }
    },
    positionStructuralUnits: {
      basePath: 'position_structural_units',
      add: {
        basePath: 'add'
      }
    },
    approval: {
      basePath: 'approval',
      add: {
        basePath: 'add'
      }
    },
    holidays: {
      basePath: 'holidays',
      add: {
        basePath: 'add'
      }
    },
    hiring: {
      basePath: 'hiring',
      list: {
        basePath: 'list'
      }
    },
    kpi: {
      basePath: 'kpi',
      list: {
        basePath: 'list'
      }
    },
    vacation: {
      basePath: 'vacation',
      list: {
        basePath: 'list'
      }
    },
    trainings: {
      basePath: 'trainings',
      list: {
        basePath: 'list'
      }
    },
    experience: {
      basePath: 'experience',
      list: {
        basePath: 'list'
      }
    },
    signatoryPersons: {
      basePath: 'signatory_persons',
      add: {
        basePath: 'add'
      }
    }
  }
}

export { routes }
