const routes = {
  basePath: 'resumes/employee',
  children: {
    experiences: {
      basePath: 'experiences'
    },
    vacations: {
      basePath: 'vacations'
    },
    documents: {
      basePath: 'documents'
    },
    benefits: {
      basePath: 'benefits'
    },
    trainings: {
      basePath: 'trainings'
    },
    actives: {
      basePath: 'actives'
    },
    notes: {
      basePath: 'notes'
    },
    education: {
      basePath: 'education'
    },
    notifications: {
      basePath: 'notifications'
    },
    alternativeContacts: {
      basePath: 'alternative_contacts'
    },
    changesAttendance: {
      basePath: 'changes_attendance'
    }
  }
}

export { routes }
