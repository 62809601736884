/** @format */

import type { AxiosRequestConfig } from 'axios';

const axiosConfig: AxiosRequestConfig = {
  // baseURL: process.env.REACT_APP_API_SERVER_URL,
  baseURL: 'https://talant.ge/api',
  timeout: 10000,
};

export { axiosConfig };
