const routes = {
  basePath: 'resumes',
  children: {
    assignment: {
      basePath: 'assignment'
    },
    resume: {
      basePath: 'resume'
    },
    organizationSchema: {
      basePath: 'organization-schema'
    },
    staff: {
      basePath: 'staff'
    }
  }
}

export { routes }
