const routes = {
  basePath: 'requests',
  children: {
    requestNotification: {
      basePath: 'notification_request'
    },
    requestVacation: {
      basePath: 'vacation_request'
    },
    requestCancellation: {
      basePath: 'cancellation_request'
    },
    requestFinAdvance: {
      basePath: 'fin_advance_request'
    },
    requestTermsChange: {
      basePath: 'terms_change_request'
    },
    requestDisciplineSanction: {
      basePath: 'discipline_sanction_request'
    },
    requestBonus: {
      basePath: 'bonus_request'
    },
    requestMission: {
      basePath: 'mission_request'
    },
    requestOvertimePay: {
      basePath: 'overtime_pay_request'
    },
    requestRecruiting: {
      basePath: 'recruiting_request'
    },
    requestPermanentStaff: {
      basePath: 'permanent_staff_request'
    },
    requestTraining: {
      basePath: 'training_request'
    },
    requestSocialHelp: {
      basePath: 'social_help_request'
    }
  }
}

export { routes }
