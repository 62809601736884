import { useQuery } from 'react-query'
import { request } from '@/utils/axiosBase/axiosBase'

export const useHeaderRequests = () => {
  const { data: notificationsCount } = useQuery('notifications-count', () =>
    request({
      url: '/notifications/notifications-count',
      method: 'get'
    })
  )

  return {
    totalNotificationsCount:
      notificationsCount?.data?.data?.notifications +
      notificationsCount?.data?.data?.requests
  }
}
