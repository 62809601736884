import snakecaseKeys from 'snakecase-keys'

export const dataToBody = (data: any) => {
  let body = null
  if (data instanceof FormData) body = data
  else if (typeof data !== 'undefined') body = snakecaseKeys(data)
  else body = undefined

  return body
}
