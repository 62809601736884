import axios from 'axios'

const client = axios.create({
  // baseURL: `${process.env.REACT_APP_API_SERVER_URL}/`
  baseURL: 'https://talant.ge/api/'
})

export function request({ ...options }) {
  client.defaults.headers.common.Authorization = `bearer ${localStorage.getItem(
    'token'
  )}`
  return client(options)
}

/* eslint-disable */
client.interceptors.response.use(
  (response: any) => new Promise((resolve: any) => resolve(response)),
  (error) => {
    if (error.response.status === 403) {
      if (error.response.config.method !== 'get') {
        console.error(error.response.data?.message)
      }
    }
    return Promise.reject(error)
  }
)
