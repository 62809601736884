import { isEmptyValue } from '@/utils/helpers/isEmptyValue'
import { HttpClient } from '@/utils/HttpClient/HttpClient'
import qs from 'qs'

class Service extends HttpClient {
  basePath: string

  constructor(basePath: string) {
    super()
    this.basePath = basePath
  }

  urlBuilder = (params?: {
    paths?: any[]
    parameters?: { [index: string]: any }[]
    anchor?: string
  }) => {
    const { paths, parameters, anchor } = Object(params)

    let url = `/${this.basePath}`

    if (paths?.length) {
      // append paths to url if exists
      url = paths.reduce((acc: any, it: any) => {
        if (typeof it !== 'undefined' && it !== null) {
          return `${acc}/${it}`
        }
        return acc
      }, url) as string
    }

    // append parameters to url if exists
    if (parameters?.length) {
      const nextParameters = parameters
        .map((value: any) => {
          const [_, val] = Object.entries(value)[0]
          if (typeof val !== 'undefined' && val !== null) {
            return value
          }
          return null
        })
        .filter((value: any) => value)

      if (nextParameters?.length) {
        url = `${url}?${qs.stringify(Object.assign({}, ...nextParameters))}`
      }
    }

    // append anchor to url if exists
    if (!isEmptyValue(anchor)) {
      url = `${url}#${anchor}`
    }

    return url
  }

  getFile = async (path: string) => {
    try {
      const response: any = await this.post('/file', {
        path
      })
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export { Service }
