/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
// utils
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import camelCaseKeys from 'camelcase-keys'
import { LocalStorage } from '@/utils/LocalStorage'
import { dataToBody } from '@/utils/helpers/dataToBody'

// configs
import { axiosConfig } from '@/configs/axios'

class HttpClient {
  protected readonly instance: AxiosInstance

  public constructor() {
    this.instance = axios.create(axiosConfig)
    this.init()
  }

  private init = () => {
    this.initialRequestInterceptor()
    this.initializeResponseInterceptor()
  }

  private initialRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      (request: AxiosRequestConfig) => {
        const token = LocalStorage.get('token')
        if (token) {
          request.headers.Authorization = `Bearer ${token}`
        }

        return request
      },
      (error) => Promise.reject(error)
    )
  }

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      ({ data }: AxiosResponse) => camelCaseKeys(data, { deep: true }),
      async (error: any) => {
        const originalRequest = error.config

        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true
          const localAccessToken = await LocalStorage.get('token')
          await axios
            .post('https://talant.ge/auth/refresh', undefined, {
              headers: {
                Authorization: `Bearer ${localAccessToken}`
              }
            })
            .then((response) => {
              error.config.headers.Authorization = `Bearer ${response.data.access_token}`
              LocalStorage.add('token', response.data.access_token)
              return this.instance(originalRequest)
            })
            .catch((_) => {
              if (localAccessToken) LocalStorage.remove('token')
              window.history.replaceState({}, '', '/auth')
            })
        }

        return Promise.reject(error)
      }
    )
  }

  request<T = any, R = AxiosResponse<T>>(
    config: AxiosRequestConfig
  ): Promise<R> {
    return this.instance.request(config)
  }

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.instance.get<T, R>(url, config)
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    const body = dataToBody(data)
    return this.instance.post<T, R>(url, body, config)
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    const body = dataToBody(data)
    return this.instance.put<T, R>(url, body, config)
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.instance.delete<T, R>(url, config)
  }
}

export { HttpClient }
